import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {
  Container,
  //Title,
  //Content,
  Button,
  //Grid,
  Text,
  A,
  Image,
  HList
} from '../components/styled'

import ProjectivalLogoUnstyled from '../assets/projectival.svg'

import {
  StarOutline as ServicesIcon,
  UserOutline as CustomersIcon,
  DocumentText as BlogIcon,
  Contacts as ContactIcon
} from 'styled-icons/typicons/'

const HeaderContainer = styled.div.attrs(props => ({
  className: `ph3 pv3`
}))``

HeaderContainer.Inner = styled(Text).attrs(props => ({
  className: `${props.className} flex items-center`
}))``

const Brand = styled.div.attrs(props => ({
  className: ``
}))`
  height: 1.5rem;
`

const ProjectivalLogo = styled(ProjectivalLogoUnstyled).attrs(props => ({
  className: ``
}))`
  width: 128px;
  height: 24px;
  
`

const Nav = styled.nav.attrs(props => ({
  className: `fixed static-l z-999 bottom-0 left-0 right-0 ml4-l shadow-1 shadow- bg-light-gray f7 f5-l`
}))`
  @media screen and (min-width: 60em) {
    &&& {
      box-shadow: none;
      background-color: transparent;
    }
  }
`

Nav.Inner = styled.ul.attrs(props => ({
  className: `list flex flex- mv0 nl3-l nr3-l pl0`
}))``

Nav.Item = styled.li.attrs(props => ({
  className: `pv2 pv0-l ph3-l w-25 w-auto-l tc tl-l`
}))`
  &:nth-child(n+5) {
    display: none;
  }
  @media screen and (min-width: 60em) {
    &:nth-child(n+5) {
      display: block;
    } 
  }
`

Nav.Icon = styled.svg.attrs(props => ({
  className: `center h2 v-mid dn-l`
}))`
  &&& {
    display: block;
  }
  @media screen and (min-width: 60em) {
    &&& {
      display: none;
    } 
  }
`

Nav.Action = styled.a.attrs(props => ({
  className: `link db ml-auto ba bw1 pv1 ph2 blue f6`
}))``

const Header = ({ siteTitle }) => {
  /*const action = {
    permalink: '/kontakt/',
    title: 'Kontakt',
  }*/
  const action = false;
  const items = [
    {
      permalink: '/leistungen/',
      title: 'Leistungen',
      icon: ServicesIcon,
    },
    {
      permalink: '/kunden/',
      title: 'Kunden',
      icon: CustomersIcon,
    },
    {
      permalink: '/blog/',
      title: 'Blog',
      icon: BlogIcon,
    },
    {
      permalink: '/kontakt/',
      title: 'Kontakt',
      icon: ContactIcon,
    },
  ]
  return (
    <HeaderContainer>
      <HeaderContainer.Inner>
        <Brand>
          <A as={Link} to="/"><Image as={ProjectivalLogo} /></A>
        </Brand>
        {items &&
          <Nav>
            <Nav.Inner>
              {items.map((item, itemIndex) =>
                <Nav.Item key={itemIndex}>
                  <A as={Link} to={item.permalink}>
                    {item.icon && <Nav.Icon as={item.icon} /> }
                    {item.title}
                  </A>
                </Nav.Item>
              )}   
            </Nav.Inner>
            <style dangerouslySetInnerHTML={{__html: `
              body {
                padding-bottom: 66px;
              }
              @media screen and (min-width: 60em) {
                body {
                  padding-bottom: 0;
                }
              }
            `}} />
          </Nav>
        }
        {action &&
          <Nav.Action as={Link} to={action.permalink}>{action.title}</Nav.Action>
        }
      </HeaderContainer.Inner>
    </HeaderContainer>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
