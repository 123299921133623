import { useStaticQuery, graphql } from "gatsby"
import urljoin from 'url-join'

export default (path) => {
	const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            pathPrefix
          }
        }
      }
    `
  )
	return urljoin(site.siteMetadata.siteUrl, site.siteMetadata.pathPrefix, path)
}