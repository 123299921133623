import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import {
  Container,
  Title,
  Content,
  Button,
  Grid,
  Card,
  A,
  Form,
  Image
} from '../components/styled'

//import Map, {Marker, InfoBox} from '../components/Map'
//import {Formik, Form as FormikForm, Field, ErrorMessage} from 'formik'
import * as emailjs from 'emailjs-com'

const AuthorImage = styled(Image).attrs(props => ({
  className: ``
}))`
  border-radius: 10% 30% 10% 30% / 30% 10% 30% 10%;
`

export default ({ data }) => {
  //console.log(data)
  return (
  <Layout>
    <SEO
      title="Kontakt Online-Marketing & Webentwicklung - Sascha Klapetz"
      description="► Schreiben oder sprechen Sie mich jetzt völlig unverbindlich an: ✓ sascha@projectival.de ✓ 0221 - 169 09 347 ✓ Raum Köln / Bonn ► Kontakt aufnehmen"
    />
    <Container>
      <Container.Inner>
        <Grid className="items-center-l">
          <Grid.Col className="mb5 w-two-thirds-l order-1-l">
            <Title as="h1">Kontakt</Title>
            <Content big full-width>
              <p>Schreiben oder sprechen Sie mich jetzt völlig unverbindlich an, und wir klären dann gemeinsam, wie ich Ihnen helfen kann.</p>
              
            </Content>
            <Title smaller className="mt3">Sascha Klapetz</Title>
            <Title smaller><A className="gtm-click" colored="true" href="mailto:sascha@projectival.de" target="_blank">sascha@projectival.de</A></Title>
            <Title smaller><A className="gtm-click" colored="true" href="tel:022116909347">0221 - 169 09 347</A></Title>
             <Button className="gtm-click" secondary="true" href="https://www.linkedin.com/in/sascha-klapetz/" target="_blank">Auf LinkedIn vernetzen</Button>
          </Grid.Col>
          <Grid.Col className="mb5 w-third-l order-2-l">
             <AuthorImage as={Img} className="center mw5" fluid={data.image.childImageSharp.fluid} />
          </Grid.Col>
        </Grid>
        
        {/*<Content center>
          <Title small>Oder füllen Sie dieses Formular aus:</Title>
          <Formik
            initialValues={{email: '', name: '', message: ''}}
            validate={values => {
              let errors = {}
              if (!values.email) {
                errors.email = 'Ihre E-Mail-Adresse benötige ich, damit ich Ihnen zurück schreiben kann.'
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Ups, wohl ein Tipper in der E-Mail-Adresse. Bitte korrigieren, damit ich Sie auch wirklich erreiche.'
              }
              if (!values.name) {
                errors.name = 'Bei meiner Antwort möchte ich Sie gerne ansprechen, alles andere wäre unhöflich.'
              }
              if (!values.message) {
                errors.message = 'Ein paar Infos wären hilfreich, damit ich mir schon mal Gedanken machen kann.'
              }
              return errors
            }}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                //alert(JSON.stringify(values, null, 2))
                //console.log(values);
                emailjs.send('default_service', 'projectival_de_contact_form', values, 'user_ytwAAKprUmkO86tofQpjT')
                  .then(response => {
                    //console.log('SUCCESS!', response.status, response.text)
                    actions.resetForm()
                    actions.setStatus({ success: 'Danke für Ihre E-Mail! Ich melde mich sobald wie möglich bei Ihnen. Versprochen.' })
                    actions.setSubmitting(false)
                  }, error => {
                    //console.log('FAILED...', error)
                    actions.setStatus({ error: 'Ihre E-Mail wurde nicht gesendet. Versuchen Sie es noch einmal, oder schreiben Sie mir direkt an <A colored="true" href="mailto:sascha@projectival.de" target="_blank">sascha@projectival.de</A>' })
                    actions.setSubmitting(false)
                  })
              }, 400)
            }}
          >
            {(x) => {
              if (!x.status) x.status = {};
              return (
              <Form className="mt3" as={FormikForm}>
                <Form.Group>
                  <Form.Label htmlFor="email">E-Mail-Adresse</Form.Label>
                  <Form.Field as={Field} id="email" type="email" name="email" full-width="true" disabled={x.isSubmitting || x.status.success} />
                  <Form.ErrorMessage as={ErrorMessage} name="email" component="div" />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="name">Name</Form.Label>
                  <Form.Field as={Field} id="name" type="text" name="name" full-width="true" disabled={x.isSubmitting || x.status.success} />
                  <Form.ErrorMessage as={ErrorMessage} name="name" component="div" />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="message">Nachricht</Form.Label>
                  <Form.Field as={Field} component="textarea" id="message" name="message" full-width="true" disabled={x.isSubmitting || x.status.success} />
                  <Form.ErrorMessage as={ErrorMessage} name="message" component="div" />
                </Form.Group>
                <Button className="gtm-click" as="button" type="submit" disabled={x.isSubmitting || x.status.success} primary>An Sascha senden</Button>
                {x.isSubmitting && <Title small>Bitte warten...</Title>}
                {x.status && <Title small className={`${(x.status.success && 'green') || (x.status.error && 'red')}`} dangerouslySetInnerHTML={{__html: x.status.success || x.status.error}} />}
              </Form>
            )}}
          </Formik>
        </Content>*/}
      </Container.Inner>
    </Container>
    {/*<Container>
      <Container.Inner>
        <Title small>Anfahrt</Title>
        <Content>
          <p>
            <strong>Projectival</strong><br />
            Marzellenstraße 43a<br />
            50668 Köln<br />
            <A className="gtm-click" href="https://goo.gl/maps/6N6Thisfgmn" external>(Link zu Google Maps)</A>
          </p>
        </Content>
        {/-*<div className="relative">
          <div className="vh-50">
            <Map>
              <Marker as="a" className="link f1" lat={50.944403} lng={6.955886} href="https://goo.gl/maps/6N6Thisfgmn" target="_blank" rel="nofollow">😀</Marker>
            </Map>
          </div>
          <Card as="a" className="absolute-l bottom-2-l left-1-l" href="https://goo.gl/maps/6N6Thisfgmn" target="_blank" rel="nofollow">
            <Card.Inner>
              <Content>
                <p>
                  <strong>Projectival</strong><br />
                  Marzellenstraße 43a<br />
                  50668 Köln<br />
                  (Link zu Google Maps)
                </p>
              </Content>
            </Card.Inner>
          </Card>
        </div>*-/}

      </Container.Inner>
    </Container>*/}
   
  </Layout>
)}

export const query = graphql`
  query {
    image: file(relativePath: { eq: "sascha-klapetz-2019-portrait.JPG" }) {
      ...flexImage
    }
  }
`