/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import getAbsoluteUrl from '../helpers/getAbsoluteUrl'

const jsonld = ({
  isArticle,
  url,
  title,
  image,
  description,
  date, // deprecated?
  dateISO,
  author,
  publisher,
  logo,
}) => {
  const jsonld = []
  /*
  const website = {
  }
  jsonld.push(website)
  */
  const article = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    'mainEntityOfPage': {
      '@type': 'WebPage',
      '@id': url
    },
    'headline': title,
    'image': [
      image,
    ],
    'datePublished': dateISO,
    //'dateModified': dateISO,
    'author': {
      '@type': 'Person',
      'name': author
    },
     'publisher': {
      '@type': 'Organization',
      'name': publisher,
      'logo': {
        '@type': 'ImageObject',
        'url': logo
      }
    },
    'description': description
  }
  if (isArticle)
     jsonld.push(article)
  return jsonld
}

function SEO({ description, lang, meta, keywords, title, page }) {
  //console.log(page)
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            lang
            jsonld {
              author
              publisher
              logo
            }
          }
        }
      }
    `
  )

  const data = {
    lang: site.siteMetadata.lang || ``,
    siteTitle: site.siteMetadata.title || ``,
    siteUrl: getAbsoluteUrl(`/`) || ``,
    url: (page && getAbsoluteUrl(page.fields.slug)) || ``,
    title: title || (page && page.frontmatter.title),
    description: description || (page && page.excerpt) || site.siteMetadata.description,
    image: (page && page.frontmatter.image && getAbsoluteUrl(page.frontmatter.image.childImageSharp.fluid.src)) || ``,
    twitterProfile: site.siteMetadata.author || ``,
    date: (page && page.frontmatter.date) || ``,
    isArticle: page ? true : false,
    author: (site.siteMetadata.jsonld && site.siteMetadata.jsonld.author) || ``,
    publisher: (site.siteMetadata.jsonld && site.siteMetadata.jsonld.publisher) || ``,
    logo: (site.siteMetadata.jsonld && site.siteMetadata.jsonld.logo) || ``,
  }
  if (data.date) {
    const dateTime = Date.parse(data.date)
    //console.log(dateTime)
    data.dateISO = dateTime ? new Date(dateTime).toISOString() : ``
  }

  //console.log(data)

  return (
    <Helmet
      htmlAttributes={{
        lang: data.lang,
      }}
      title={data.title}
      titleTemplate={`%s | ${data.siteTitle}`}
      meta={[
        {
          name: `description`,
          content: data.description,
        },
        /* Open Graph */
        {
          property: `og:type`,
          content: data.isArticle ? `article` : `website`,
        },
        {
          property: `og:url`,
          content: data.url,
        },
        {
          property: `og:title`,
          content: data.title,
        },
        {
          property: `og:description`,
          content: data.description,
        },
        {
          name: `image`,
          property: `og:image`,
          content: data.image,
        },
        /* Twitter Cards */
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: data.twitterProfile,
        },
        {
          name: `twitter:title`,
          content: data.title,
        },
        {
          name: `twitter:description`,
          content: data.description,
        },
        {
          name: `twitter:image`,
          content: data.image,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify(jsonld({...data}))}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
