import React from 'react'
//import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import {
  Container,
  Title,
  Content,
  //Button,
  List,
  HList,
  Grid,
  A,
} from '../components/styled'

import {Linkedin, Xing, Facebook, Instagram} from 'styled-icons/fa-brands/'

const SocialIcon = styled.svg.attrs(props => ({
  className: `w2 h2 v-mid`
}))``

const Footer = () => (
  <>
    <Container>
      <Container.Inner>
        <Grid className="nb4">
          <Grid.Col className="mb4 w-50-l">
            {/*<Title smaller>Kontakt</Title>*/}
            <div className="mb4">
              <HList>
                <HList.Item><A className="gtm-click" href="https://www.linkedin.com/in/sascha-klapetz/" external><SocialIcon as={Linkedin} /></A></HList.Item>
                <HList.Item><A className="gtm-click" href="https://www.xing.com/profile/Sascha_Klapetz" external><SocialIcon as={Xing} /></A></HList.Item>
                <HList.Item><A className="gtm-click" href="https://www.facebook.com/projectival" external><SocialIcon as={Facebook} /></A></HList.Item>
                <HList.Item><A className="gtm-click" href="https://www.instagram.com/projectival/" external><SocialIcon as={Instagram} /></A></HList.Item>
              </HList>
            </div>
            <Content>
              <p>
                <strong>Projectival</strong> - Sascha Klapetz<br />
                <A className="gtm-click" href="https://goo.gl/maps/iZRf7tRLWvUbdcAv9" external>Lichtstraße 21, 50825 Köln</A><br />
                <A className="gtm-click" href="mailto:sascha@projectival.de" target="_blank">sascha@projectival.de</A><br />
                <A className="gtm-click" href="tel:022116909347">0221 - 169 09 347</A>
              </p>
            </Content>
          </Grid.Col>
          <Grid.Col className="mb4 w-50-l">
            {/*<Title smaller>Seiten</Title>*/}
            <List>
              <List.Item><A as={Link} to="/leistungen/">Leistungen</A></List.Item>
              <List.Item><A as={Link} to="/kunden/">Kunden</A></List.Item>
              <List.Item><A as={Link} to="/blog/">Blog</A></List.Item>
              <List.Item><A as={Link} to="/kontakt/">Kontakt</A></List.Item>
            </List>
            <List>
              <List.Item><A as={Link} to="/datenschutz/">Datenschutz</A></List.Item>
              <List.Item><A as={Link} to="/impressum/">Impressum</A></List.Item>
            </List>
          </Grid.Col>
          {/*<Grid.Col className="mb4 w-third-l">
            <Title smaller dangerouslySetInnerHTML={{__html: '<*)))>'}} />
          </Grid.Col>*/}
        </Grid>
      </Container.Inner>
    </Container>
  </>
)

/*
Footer.propTypes = {
  
}

Footer.defaultProps = {
  
}
*/

export default Footer
