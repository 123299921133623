import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import CookieConsent from 'react-cookie-consent'

import Header from './header'
import Footer from './Footer'
//import './layout.css'
import 'tachyons'
import './tachyons-extend.css'
import 'typeface-work-sans'

import {
  Text,
  Button
} from '../components/styled'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        
        <Header siteTitle={data.site.siteMetadata.title} />
        {/*<div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          {children}
          <footer>
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </footer>
        </div>*/}
        {children}
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Verstanden"
          cookieName="cookieconsent_dismissed"
          containerClasses="items-center"
          style={{ alignItems: "center", background: "#2B373B" }}
          expires={150}
          buttonClasses="link dib ba f4 sans-serif lh-copy b br2 b--blue bg-blue white shadow-5 pv2 ph3 button-reset bn pointer font-family-work-sans ma3"
          disableButtonStyles="true"
          acceptOnScroll="true"
        >
          <Text>Diese Internetseite verwendet Cookies, um die Nutzererfahrung zu verbessern und den Benutzern bestimmte Dienste und Funktionen bereitzustellen. <Link className="link white" to="/datenschutz/">Details</Link></Text>
        </CookieConsent>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
