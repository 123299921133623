import styled from 'styled-components'

export const Container = styled.div.attrs(props => ({
	className: `pv5 ph3 ph5-l`
}))``

Container.Inner = styled.div.attrs(props => ({
	className: `center ${(props.narrow && 'mw7') || 'mw8'} ${(props['narrow-m'] && 'mw6-m') || 'mw7-m'}`
}))``

export const Title = styled.div.attrs(props => ({
	className: `db mt0 mb3 sans-serif lh-title fw7 i ${props.center && 'tc'} ${((props.smaller && 'f3 f3-l') || (props.small && 'f3 f2-l')) || 'f2 f1-l'}`
}))`
	&& {
		font-family: 'Work Sans', sans-serif;
	}
`

export const Text = styled.div.attrs(props => ({
	className: `sans-serif lh-copy fw4`
}))`
	&& {
		font-family: 'Work Sans', sans-serif;
	}
`

export const List = styled.ul.attrs(props => ({
	className: `list pl0 sans-serif lh-copy fw4`
}))`
	margin-top: 0;
	margin-bottom: 0;
	&& {
		font-family: 'Work Sans', sans-serif;
	}
`

List.Item = styled.li.attrs(props => ({
	className: ``
}))``

export const HList = styled(List).attrs(props => ({
  className: `${props.className} nl3 nr3`
}))``

HList.Item = styled(List.Item).attrs(props => ({
  className: `${props.className} dib ph3`
}))``

export const Content = styled(Text).attrs(props => ({
	className: `${props.className} db ${(props.mb && 'mt0 mb4') || 'mv0'} ${props.center && 'center tc'} ${(props.big && 'f4') || 'f5'} ${!props['full-width'] && 'measure'}`
}))`
	p:not([class]) {
		margin-top: 0;
	}
	*:not([class]):last-child {
		margin-bottom: 0;
	}
	figure:not([class]),
	figure.gatsby-resp-image-figure {
		margin-top: 0;
		margin-left: 0;
		margin-right: 0;
		p {
			margin-bottom: 0;
		}
	}
	.gatsby-highlight {
		margin-bottom: 1rem;
	}
	h2:not(:first-child) {
		margin-top: 4rem;
	}

`

export const Button = styled.a.attrs(props => ({
	className: `link dib ba f4 sans-serif lh-copy b ${(props.primary && 'br2 b--blue bg-blue white shadow-5') || (props.secondary && 'br2 b--blue blue') || 'b--transparent blue'} ${(props.small && 'pv2 ph3') || 'pv3 ph4'} ${props.as==='button' && 'button-reset bn pointer'}`
}))`
	&& {
		font-family: 'Work Sans', sans-serif;
	}
	&&&:disabled {
		${props => props.primary && 'background-color: #999999;'}
	}
`

export const Grid = styled.div.attrs(props => ({
	className: 'flex-l nl5-l nr5-l'
}))``

Grid.Col = styled.div.attrs(props => ({
	className: 'ph5-l'
}))``

export const A = styled.a.attrs(props => ({
  className: `link underline-hover ${props.colored && 'blue'}`,
  target: props.external && '_blank',
  rel: props.external && 'nofollow',
}))`
  ${props => !props.colored && 'color: inherit;'}
`

export const Gallery = styled(List).attrs(props => ({
  className: `${props.className} flex flex-wrap items-center justify-center nb3 nl3 nr3`
}))``

Gallery.Item = styled(List.Item).attrs(props => ({
  className: `${props.className} mb3 ph3`
}))``

export const Image = styled.img.attrs(props => ({
  className: `v-mid`
}))``

export const Figure = styled.figure.attrs(props => ({
  className: `ma0`
}))``

Figure.Caption = styled.figcaption.attrs(props => ({
  className: `silver f6`
}))``

export const Card = styled.div.attrs(props => ({
  className: `flex bg-light-gray ${(props.to || props.href) && 'link db blue'}`
}))`
	border-radius: 10% 30% 10% 30% / 30% 10% 30% 10%;
	overflow: hidden;

	@media screen and (min-width: 60em) {
		&&& {
			display: block;
		}
	}
`

Card.ImageWrapper = styled.div.attrs(props => ({
  className: `w-third w-auto-l`
}))`
	flex-shrink: 0;
	${Image} {
		height: 100%;
	}
`

Card.Inner = styled.div.attrs(props => ({
  className: `pa4`
}))``

export const Form = styled.form.attrs(props => ({
  className: ``
}))``

Form.Group = styled.div.attrs(props => ({
  className: `mb3`
}))``

Form.Label = styled.label.attrs(props => ({
  className: `db`
}))`
	&& {
		font-family: 'Work Sans', sans-serif;
	}
`

Form.Field = styled.input.attrs(props => ({
  className: `input-reset ba pa3 ${props['full-width'] && 'w-100'}`
}))`
	&& {
		font-family: 'Work Sans', sans-serif;
	}
	&&&:disabled {
		background-color: #eeeeee;
	}
`

Form.ErrorMessage = styled.label.attrs(props => ({
  className: `red`
}))`
	&& {
		font-family: 'Work Sans', sans-serif;
	}
`
